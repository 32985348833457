import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Select" component="select" variation="select" hasReactVersion hasLightVersion hasReactVersion hasVueVersion="select--default" hasAngularVersion="?path=/story/select--basic" codepen="oOxzmZ" mdxType="ComponentCode" />
    <ComponentCode name="Select with helper text" component="select" variation="select--helpertext" hasReactVersion hasVueVersion="select--default&knob-helper%20text=helpful%20text" codepen="wZGzZY" mdxType="ComponentCode" />
    <ComponentCode name="Select invalid" component="select" variation="select--invalid" hasReactVersion hasVueVersion="select--default&knob-invalid%20message=Invalid selection" codepen="MRyjMg" mdxType="ComponentCode" />
    <ComponentCode name="Inline select" component="select" variation="select--inline" hasReactVersion hasVueVersion="select--default&knob-inline=true" hasAngularVersion="?path=/story/select--basic&knob-Theme=dark&knob-Display=inline" codepen="qwZaer" mdxType="ComponentCode" />
    <ComponentCode name="Inline select with helper text" component="select" variation="select--helpertextinline" hasReactVersion hasVueVersion="select--default&knob-inline=true&knob-helper%20text=helpful%20text" codepen="VNammQ" mdxType="ComponentCode" />
    <ComponentCode name="Inline select invalid" component="select" variation="select--inlineinvalid" hasReactVersion hasVueVersion="select--default&knob-inline=true&knob-invalid%20message=Invalid selection" codepen="ZZWBLQ" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="select" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      